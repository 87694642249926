import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Content from '@solid-ui-blocks/Content/Block01_rechtliches'
import Footer from '@solid-ui-blocks/Footer/Block01'

import Hero from '@solid-ui-blocks/Hero/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const AboutUs01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo 
      title='AGB | Bitconcepts' 
      description='Dies sind die allgemeinen Geschäftsbedingungen von Bitconcepts - Agentur für Digitalmarketing in Cloppenburg.'
      />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
        <Divider space='4' />
        <Contact content={content['cta']} />
      </Container>
      <Divider space='4' />
      <Content content={content['content-one']} />
      <Divider space='4' />
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageSiteAGBAboutUs01BlockContent {
    allBlockContent(
      filter: { page: { in: ["site/agb", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default AboutUs01
